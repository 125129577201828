import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">HERE’S WHAT PEOPLE ARE SAYING ABOUT WE ROCK THE SPECTRUM KIDS GYMS AROUND THE WORLD...</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            “My hyper child and my younger child both LOVED this place. Clean, fun and is super equipped with sensory stimulation. Thanks DINA!!!”                            </p>
                            <p className="overview">Jenny R.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “This place is amazing! My wife and I had been looking for a good place to bring our 4 year old son for a while and heard about “We Rock the Spectrum Kids Gym” from a good friend.”                            </p>
                            <p className="overview">Jim M. </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “BEST PLACE!… My kids go here every time school is out for winter or spring break. Dina is amazing, place is clean, she brings in all sorts of people to make it interesting for the kids."                            </p>
                            <p className="overview">
                            Ken & Monique F.                             </p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                            “We love this place. My son is 5 and his dad feels like this is one of the few places they cay really play full-out INSIDE! The zip line, trampoline and crafts room are a huge hit with my son."                            </p>
                            <p className="overview">
                            Deborah S. 
                                                        </p>
                        </div> 
                       
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
